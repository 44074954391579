@import url('https://fonts.googleapis.com/css?family=PT+Sans');

.browser-window-loaded{
  border-radius: 5px 5px 0 0;
  box-shadow: -3px 3px 10px 0 #252525;
  width: 80%;
  max-width: 1150px;
  min-width: 1120px;
  height: 800px;
  margin: 0 auto;
  margin-top:5%;
  background-image: url('../resources/project-background.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.browser-window-loading{
  border-radius: 5px 5px 0 0;
  box-shadow: -3px 3px 10px 0 #252525;
  width: 80%;
  max-width: 1150px;
  min-width: 1120px;
  height: 800px;
  margin: 0 auto;
  margin-top:5%;
  background: #F7F7F7;
}


.browser-bar{
  font-family: 'PT Sans', sans-serif;
  background-image: url('../resources/firefox-bar.PNG');
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 15%;
  margin: 0 auto;
  border-radius: 5px 5px 0 0;
}
.search-pos{
  height: 100%;
  width: 50%;
  margin-left: 11%;
}
.search-bar{
  width: 60%;
  height: 17%;
  margin-left: 21%;
  position: relative;
  top: 56%;
  color: #626262;
}
.display-projects-container{
  font-family: 'PT Sans', sans-serif;
  height: 85%;
}

.display-projects{
  height: 100%;
  width: 100%;
  overflow: auto;
}
.project-container{
  height: 100%;
  margin: 0 auto;
  width: 50%;
  text-align: center;
  color: black;
}

.project-img{
  box-shadow: -2px 2px 4px 0 #0000005e;
}

.project-nav-container{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.project-nav:hover{
  background-color: rgba(37, 0, 71, 0.432);
  color: whitesmoke;
  box-shadow: -1px 1px 4px 0 #bdbdbdbd;
}

.project-nav{
  border: none;
  text-decoration: none;
  font-family: 'PT Sans', sans-serif;
  font-weight: bold;
  border-radius: 4px;
  padding: 1px 8px 1px 8px;
  background: linear-gradient(0deg, rgba(159, 159, 159, 0.49) 0%, rgba(175, 175, 175, 0.4) 45%, rgba(184, 184, 184, 0.45) 100%);
  color: black;
  box-shadow: -1px 2px 2px 0 #00000073;
  transition: 0.5s;
  
}
.mobile-projects{
  display: none;
}
.project-btn{
  border: 1px solid #929292;
  border-radius: 2px;
  margin: 0px 10px 0px 10px;
  background: #e2e2e278;
  box-shadow: -1px 1px 4px 0 #000000bd;
  width: 20%;
  font-weight: bold;
  font-family: 'PT Sans', sans-serif;
  transition: 0.5s;
}
.project-btn:hover{
  background-color: rgba(37, 0, 71, 0.425);
  color: whitesmoke;
  border: none;
  box-shadow: -1px 1px 4px 0 #bdbdbdbd;
}

@media screen and (max-width: 900px){

.project-page-container{
  display: none
}
.mobile-projects{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'PT Sans', sans-serif;
  color: black;
}
.mobile-projects img{
  width: 80%;
}
.project-nav-container{
  background: linear-gradient(0deg, rgba(159,159,159,1) 0%, rgba(175,175,175,1) 45%, rgba(184,184,184,1) 100%);
  box-shadow: -1px 1px 10px 0 black;
  padding: 5px;
}
.project-nav{
  background: none;
  box-shadow: none;
  font-size: 15px;
}
.main-content{
  padding: 5px;
}

}

@media screen and (min-width: 2300px){
  .browser-window-loaded{
    margin-top: 10%;
  }
  .browser-window-loading{
    margin-top: 10%;
  }
}