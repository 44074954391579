.App{
  color: rgb(212, 212, 212);
  font-family: 'Ubuntu Mono', monospace;
}
html{
  background-image: url('./resources/portfolio background1080.PNG');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #f0f0f0;
  margin: 0;
  padding: 0;
}
a{
  cursor: pointer;  
}
button{
  cursor: pointer;
}

@media screen and (max-width: 900px){
  body{
    background: linear-gradient(0deg, rgba(195,188,163,1) 0%, rgba(176,148,148,1) 45%, rgba(160,159,185,1) 100%);
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: 100vh;
  }
}

