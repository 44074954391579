@import url('https://fonts.googleapis.com/css?family=Work+Sans');

.Cursor--blinking {
  opacity: 1;
  animation: blink 1s linear infinite;
  color:rgb(212, 212, 212);
}
  @keyframes blink {
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
  }

  .Terminal{
    border-radius:4px;
    width: 800px;
    height: 600px;
    margin: 0 auto;
    margin-top:10%;
    background: #0d0e0fa8;
    box-shadow: -2px 2px 7px 1px #00000061;
  }
  
  .exit{
    border: 1px solid rgb(46, 46, 46);
    border-radius: 50%;
    height:20px;
    width: 20px;
    margin: 5px;
    font-size: 8px;
    font-weight: bolder;
    text-align: center;
    padding:0;
    background: #47474F;
  }
  .minimize{
    border: 1px solid rgb(46, 46, 46);
    border-radius: 50%;
    height:20px;
    width: 20px;
    margin: 5px;
    font-size: 8px;
    font-weight: bolder;
    background: #47474F
    
  }
  .expand{
    border: 1px solid rgb(46, 46, 46);
    border-radius: 50%;
    height:20px;
    width: 20px;
    margin: 5px;
    font-size: 8px;
    font-weight: bolder;
    background: #47474F
  
  }
  
  .windowBar{
    border-radius: 4px 4px 0 0;
    padding-top: 6px;
    background: linear-gradient(0deg, rgba(199,199,199,1) 0%, rgba(205,205,205,1) 45%, rgba(227,227,227,1) 100%);
  }
  
  .nav-link{
    background:rgb(212, 212, 212);
    color: #2E3235;
    text-decoration: none;
    font-family: 'Ubuntu Mono', monospace;
  }

  .menu-bar{
    color: #2E3235;
    font-family: 'Work Sans', sans-serif;
    margin-left: 10px;
    font-size: 17px;
  }

  .button-menu{
    float: right;
  }

  .window-header{
    margin-left: 45%;
    color: #2E3235;
    font-weight: bold;
    font-family: 'Work Sans', sans-serif;

  }

  @media screen and (max-width: 900px){

    .Terminal{
      background: #0d0e0f;
      margin-top: 0;
      border-radius: none;
      width: 100%;
      height: 100vh;
      font-size: 80%;
    }
    .window-header{
      margin-left: 38%;
    }

  }

  @media screen and (min-width: 2300px){

  }
