@import url(https://fonts.googleapis.com/css?family=Work+Sans);
@import url(https://fonts.googleapis.com/css?family=PT+Sans);
@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App{
  color: rgb(212, 212, 212);
  font-family: 'Ubuntu Mono', monospace;
}
html{
  background-image: url("/static/media/portfolio background1080.66c1402a.PNG");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #f0f0f0;
  margin: 0;
  padding: 0;
}
a{
  cursor: pointer;  
}
button{
  cursor: pointer;
}

@media screen and (max-width: 900px){
  body{
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(195,188,163,1)), color-stop(45%, rgba(176,148,148,1)), to(rgba(160,159,185,1)));
    background: -webkit-linear-gradient(bottom, rgba(195,188,163,1) 0%, rgba(176,148,148,1) 45%, rgba(160,159,185,1) 100%);
    background: linear-gradient(0deg, rgba(195,188,163,1) 0%, rgba(176,148,148,1) 45%, rgba(160,159,185,1) 100%);
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: 100vh;
  }
}


.Cursor--blinking {
  opacity: 1;
  -webkit-animation: blink 1s linear infinite;
          animation: blink 1s linear infinite;
  color:rgb(212, 212, 212);
}
  @-webkit-keyframes blink {
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
  }
  @keyframes blink {
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
  }

  .Terminal{
    border-radius:4px;
    width: 800px;
    height: 600px;
    margin: 0 auto;
    margin-top:10%;
    background: #0d0e0fa8;
    box-shadow: -2px 2px 7px 1px #00000061;
  }
  
  .exit{
    border: 1px solid rgb(46, 46, 46);
    border-radius: 50%;
    height:20px;
    width: 20px;
    margin: 5px;
    font-size: 8px;
    font-weight: bolder;
    text-align: center;
    padding:0;
    background: #47474F;
  }
  .minimize{
    border: 1px solid rgb(46, 46, 46);
    border-radius: 50%;
    height:20px;
    width: 20px;
    margin: 5px;
    font-size: 8px;
    font-weight: bolder;
    background: #47474F
    
  }
  .expand{
    border: 1px solid rgb(46, 46, 46);
    border-radius: 50%;
    height:20px;
    width: 20px;
    margin: 5px;
    font-size: 8px;
    font-weight: bolder;
    background: #47474F
  
  }
  
  .windowBar{
    border-radius: 4px 4px 0 0;
    padding-top: 6px;
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(199,199,199,1)), color-stop(45%, rgba(205,205,205,1)), to(rgba(227,227,227,1)));
    background: -webkit-linear-gradient(bottom, rgba(199,199,199,1) 0%, rgba(205,205,205,1) 45%, rgba(227,227,227,1) 100%);
    background: linear-gradient(0deg, rgba(199,199,199,1) 0%, rgba(205,205,205,1) 45%, rgba(227,227,227,1) 100%);
  }
  
  .nav-link{
    background:rgb(212, 212, 212);
    color: #2E3235;
    text-decoration: none;
    font-family: 'Ubuntu Mono', monospace;
  }

  .menu-bar{
    color: #2E3235;
    font-family: 'Work Sans', sans-serif;
    margin-left: 10px;
    font-size: 17px;
  }

  .button-menu{
    float: right;
  }

  .window-header{
    margin-left: 45%;
    color: #2E3235;
    font-weight: bold;
    font-family: 'Work Sans', sans-serif;

  }

  @media screen and (max-width: 900px){

    .Terminal{
      background: #0d0e0f;
      margin-top: 0;
      border-radius: none;
      width: 100%;
      height: 100vh;
      font-size: 80%;
    }
    .window-header{
      margin-left: 38%;
    }

  }

  @media screen and (min-width: 2300px){

  }

.AboutMe-message{
  margin: 5px 0 0 10px;
}
.visible{
  margin: 5px 0 0 10px;
}
.hidden{
  display: none;
}
.aboutMe-heading{
  text-align: center;
  font-weight: bold;
  color: rgb(255, 57, 57);
}

.skills-message{
  text-align: left;
}
.skill-title{
  font-weight: 600;
  color: rgb(255, 57, 57);
}

@media screen and (max-width: 900px){}

@media screen and (min-width: 2300px){}
.browser-window-loaded{
  border-radius: 5px 5px 0 0;
  box-shadow: -3px 3px 10px 0 #252525;
  width: 80%;
  max-width: 1150px;
  min-width: 1120px;
  height: 800px;
  margin: 0 auto;
  margin-top:5%;
  background-image: url(/static/media/project-background.4610cb49.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.browser-window-loading{
  border-radius: 5px 5px 0 0;
  box-shadow: -3px 3px 10px 0 #252525;
  width: 80%;
  max-width: 1150px;
  min-width: 1120px;
  height: 800px;
  margin: 0 auto;
  margin-top:5%;
  background: #F7F7F7;
}


.browser-bar{
  font-family: 'PT Sans', sans-serif;
  background-image: url(/static/media/firefox-bar.903e2d60.PNG);
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 15%;
  margin: 0 auto;
  border-radius: 5px 5px 0 0;
}
.search-pos{
  height: 100%;
  width: 50%;
  margin-left: 11%;
}
.search-bar{
  width: 60%;
  height: 17%;
  margin-left: 21%;
  position: relative;
  top: 56%;
  color: #626262;
}
.display-projects-container{
  font-family: 'PT Sans', sans-serif;
  height: 85%;
}

.display-projects{
  height: 100%;
  width: 100%;
  overflow: auto;
}
.project-container{
  height: 100%;
  margin: 0 auto;
  width: 50%;
  text-align: center;
  color: black;
}

.project-img{
  box-shadow: -2px 2px 4px 0 #0000005e;
}

.project-nav-container{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.project-nav:hover{
  background-color: rgba(37, 0, 71, 0.432);
  color: whitesmoke;
  box-shadow: -1px 1px 4px 0 #bdbdbdbd;
}

.project-nav{
  border: none;
  text-decoration: none;
  font-family: 'PT Sans', sans-serif;
  font-weight: bold;
  border-radius: 4px;
  padding: 1px 8px 1px 8px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(159, 159, 159, 0.49)), color-stop(45%, rgba(175, 175, 175, 0.4)), to(rgba(184, 184, 184, 0.45)));
  background: -webkit-linear-gradient(bottom, rgba(159, 159, 159, 0.49) 0%, rgba(175, 175, 175, 0.4) 45%, rgba(184, 184, 184, 0.45) 100%);
  background: linear-gradient(0deg, rgba(159, 159, 159, 0.49) 0%, rgba(175, 175, 175, 0.4) 45%, rgba(184, 184, 184, 0.45) 100%);
  color: black;
  box-shadow: -1px 2px 2px 0 #00000073;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  
}
.mobile-projects{
  display: none;
}
.project-btn{
  border: 1px solid #929292;
  border-radius: 2px;
  margin: 0px 10px 0px 10px;
  background: #e2e2e278;
  box-shadow: -1px 1px 4px 0 #000000bd;
  width: 20%;
  font-weight: bold;
  font-family: 'PT Sans', sans-serif;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.project-btn:hover{
  background-color: rgba(37, 0, 71, 0.425);
  color: whitesmoke;
  border: none;
  box-shadow: -1px 1px 4px 0 #bdbdbdbd;
}

@media screen and (max-width: 900px){

.project-page-container{
  display: none
}
.mobile-projects{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'PT Sans', sans-serif;
  color: black;
}
.mobile-projects img{
  width: 80%;
}
.project-nav-container{
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(159,159,159,1)), color-stop(45%, rgba(175,175,175,1)), to(rgba(184,184,184,1)));
  background: -webkit-linear-gradient(bottom, rgba(159,159,159,1) 0%, rgba(175,175,175,1) 45%, rgba(184,184,184,1) 100%);
  background: linear-gradient(0deg, rgba(159,159,159,1) 0%, rgba(175,175,175,1) 45%, rgba(184,184,184,1) 100%);
  box-shadow: -1px 1px 10px 0 black;
  padding: 5px;
}
.project-nav{
  background: none;
  box-shadow: none;
  font-size: 15px;
}
.main-content{
  padding: 5px;
}

}

@media screen and (min-width: 2300px){
  .browser-window-loaded{
    margin-top: 10%;
  }
  .browser-window-loading{
    margin-top: 10%;
  }
}

.modal {
  position: fixed;
  top: 22%;
  left: 33.5%;
  padding: 12px;
  background-color: white;
  border: 1px solid grey;
  overflow-x: auto;
  max-height: calc(100vh - 210px);
  width: 30%;
}

@media screen and (max-width: 900px){
  .modal{
    width: 90%;
    top: 6%;
    left: 1.5%;
    background-color: #ffffffd9;
    border: none;
    border-radius: 4px;
  }
  .modal-confirm-button{
    width: 40%;
    margin-right: 10%;
  }
  .modal-close-button{
    width: 40%;
    margin-left: 10%;
  }
}

@media screen and (min-width: 2300px){
  .modal{
    top: 26%;
  }
}
.snapshot-container{
  display: flex;
  justify-content: space-around;
  margin-top: 5%;
}
.github-window img{
  border-radius: 2px 2px 0 0;
  box-shadow: -3px 3px 4px 0 #212121a1;
  -webkit-transition: ease-out 0.9s;
  transition: ease-out 0.9s;
}
.github-window img:hover{
  -webkit-transition: 0.6s ease-in-out;
  transition: 0.6s ease-in-out;
  opacity: 0.9;
  -webkit-filter: blur(0.7px);
          filter: blur(0.7px)

}
.linkedin-window img:hover{
  -webkit-transition: 0.6s ease-in-out;
  transition: 0.6s ease-in-out;
  opacity: 0.9;
  -webkit-filter: blur(0.7px);
          filter: blur(0.7px)
}
.linkedin-window img{
  border-radius: 2px 2px 0 0;
  box-shadow: -3px 3px 4px 0 #212121a1;
  -webkit-transition: ease-out 0.9s;
  transition: ease-out 0.9s;
}

.app-icons{
  display: flex;
  justify-content: space-around;
  margin-top: 1%;
  height: 50px;
}


.tar-icon{
  display: grid;
}

.firefox-icon{
  display: grid;
}

.firefox-icon a{
  margin: 0 auto;
}

.tar-icon a{
  margin: 0 auto;
}

.icon{
width: 40px;
height: 40px;
margin: 0 auto;
-webkit-transition: 0.4s ease;
transition: 0.4s ease;
-webkit-animation: pulse 1.5s infinite;
        animation: pulse 1.5s infinite
}

.icon:hover{
background: rgba(255, 255, 255, 0.425);
box-shadow: 0 0 7px 0 white;
-webkit-animation: 0s;
        animation: 0s;
}

.mobile-contact{
  display: none;
}


@-webkit-keyframes pulse {
  0% {
    width: 40px;
    height: 40px;
  }

  50% {
    width: 42px;
    height: 42px;
  }

  100% {
    width: 40px;
    height: 40px;
  }
}


@keyframes pulse {
  0% {
    width: 40px;
    height: 40px;
  }

  50% {
    width: 42px;
    height: 42px;
  }

  100% {
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-width: 900px){
  .full-contact{
    display: none;
  }
  .mobile-contact{
    display: block;
  }
  .email-main-container {
    width: 90%;
  }
  .contact-links a{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .contact-links {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .gh-m-img{
    width: 30%;
    margin-top: 27.3%;
    -webkit-transition: 3s ease-in-out;
    transition: 3s ease-in-out;
    -webkit-animation: pulse-icon 1.4s infinite;
            animation: pulse-icon 1.4s infinite;
    cursor: pointer;
  }
  .gm-m-img{
    width: 37%; 
    -webkit-transition: 1s ease-in-out; 
    transition: 1s ease-in-out;
    -webkit-animation: pulse-icon 1.3s infinite;
            animation: pulse-icon 1.3s infinite;
    cursor: pointer;
  }
  .li-m-img{
    width: 30%;
    margin-top: 6%;
    -webkit-transition: 5s ease-in-out;
    transition: 5s ease-in-out;
    -webkit-animation: pulse-icon 1.5s infinite;
            animation: pulse-icon 1.5s infinite;
    cursor: pointer;
  }
  .contact-nav{
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(159,159,159,1)), color-stop(45%, rgba(175,175,175,1)), to(rgba(184,184,184,1)));
    background: -webkit-linear-gradient(bottom, rgba(159,159,159,1) 0%, rgba(175,175,175,1) 45%, rgba(184,184,184,1) 100%);
    background: linear-gradient(0deg, rgba(159,159,159,1) 0%, rgba(175,175,175,1) 45%, rgba(184,184,184,1) 100%);
    display: flex;
    justify-content: space-around;
    box-shadow: -1px 1px 10px 0 black;
    padding: 5px;
  }
  .contact-nav a{
    text-decoration: none;
    font-weight: bold;
    color: black;
    font-family: 'PT Sans', sans-serif;
    
  }
  @-webkit-keyframes pulse-icon {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.7;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes pulse-icon {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.7;
    }
    100% {
      opacity: 1;
    }
  }
}

@media screen and (min-width: 2300px){
  .linkedin-window img{
    width: 130%;
  }
  .github-window img{
    width: 130%;
  }
  .email-main-container{
    width: 700px;
    height: 550px;
  }
  .email-body{
    height: 360px;
  }
  .snapshot-container{
    margin-top: 6%;
  }
}

.email-main-container{
  font-family: 'Roboto', sans-serif;
  width:500px;
  height: 350px;
  background: whitesmoke;
  border-radius: 4px 4px 0 0;
  margin: 0 auto;
  box-shadow: -3px 3px 4px 0 #212121a1;
}

.email-form-container{
  display: block;
  text-align: center;
}
.email-header{
border-radius: 4px 4px 0 0;
box-shadow: 0px 1px 2px 0 #00000047;
background:-webkit-gradient(linear, left bottom, left top, from(rgba(228,57,14,1)), color-stop(45%, rgba(217,73,37,1)), to(rgba(223,101,70,1)));
background:-webkit-linear-gradient(bottom, rgba(228,57,14,1) 0%, rgba(217,73,37,1) 45%, rgba(223,101,70,1) 100%);
background:linear-gradient(0deg, rgba(228,57,14,1) 0%, rgba(217,73,37,1) 45%, rgba(223,101,70,1) 100%);
}

.email-header h3{
  padding: 4px;
  color: #080808;
  font-size: 15px;
}

.email-address{
  width:95%;
  display: inline-block;
  margin-top: 2%;
  border: none;
  border-bottom: 1px solid rgb(179, 179, 179);
  background: whitesmoke;
}

.email-subject{
  width: 95%;
  display: inline-block;
  margin-top: 2%;
  border: none;
  border-bottom: 1px solid rgb(179, 179, 179);
  background: whitesmoke;
}

.email-body{
  width: 95%;
  height: 190px;
  display: inline-block;
  border: none;
  overflow: auto;
  margin-top: 2%;
  background: whitesmoke;
}

textarea{
  resize: none;
}
.btn-submit{
  margin-left: 75%;
  border: none;
  border-radius: 4px;
  width: 70px;
  padding: 5px;
  box-shadow: -1px 2px 3px 0 #3c3c3cc7;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(159,159,159,1)), color-stop(45%, rgba(175,175,175,1)), to(rgba(184,184,184,1)));
  background: -webkit-linear-gradient(bottom, rgba(159,159,159,1) 0%, rgba(175,175,175,1) 45%, rgba(184,184,184,1) 100%);
  background: linear-gradient(0deg, rgba(159,159,159,1) 0%, rgba(175,175,175,1) 45%, rgba(184,184,184,1) 100%);
  
}

@media screen and (max-width: 900px){
  .email-main-container{
    margin-bottom: 10%;
    width: 80%;
  }
  .email-header h3{
    margin-top: 0;
  }
  .btn-submit{
    margin-left: 0;
  }
}

@media screen and (min-width: 2300px){}

.HomePage-message{
  margin: 5px 0 0 10px;
}

.entryCommand{
  margin: 5px 0 0 10px;
  display: inline-flex;
}

.root{
  color: rgb(255, 57, 57);
  font-weight: bold;
  margin-right: 10px;
}

.installing{
  display: flex;
  margin-left: 10px;
}
.hidden{
  display: none;
}

@media screen and (max-width: 900px){
  .menu-bar-container span{
    font-size: 80%;
  }
  .button-menu button{
    width: 15px;
    height: 15px;
  }
  
}

@media screen and (min-width: 2300px){}
